.contact-form {
    width: 100%;
    margin-left: 0;
    padding-left: 10rem;

    &.register {
        width: 40%;
        margin-left: 30%;
    }
}

@media (max-width: 768px) {

    .contact-form {
        margin-left: 0;
        width: 100%;

        &.conference {
            padding-left: 0;
        }
    }
}
