.map {
  height: 650px;
  margin-top: 0 !important;
  padding-top: 5rem !important;
  background: $color-white;

  &.s {
    height: 500px;

    .map-container {
      height: 500px;
    }
  }


  .map-container {
    height: 650px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &.s {
      height: 450px;
    }
  }

  .map-detail-container {
    position: absolute;
    right: 0;
    top: 0;
    background: $color-white;
    border-radius: 5px;
    //overflow: hidden;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
    color: $color-black;
    width: 400px;
    min-height: 500px;

    .map-detail__img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 200px;
    }

    .map-detail__content {
      margin-top: 200px;
      padding: 3rem;

      h2 {
        font-size: 2.2rem;
        margin: 0 0 1rem 0;
      }

      h3 {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;

        img {
          height: 2rem;
          margin-right: 1rem;
        }

        span {
          font-size: 1.5rem;
          font-weight: 300;
          text-align: left;
        }
      }

      p {
        font-size: 1.4rem;
        line-height: 2.2rem;
        margin-bottom: 2rem;
      }
    }
  }
}

@media (max-width: 768px) {

  .map {
    padding-bottom: 2rem !important;
    height: 950px;

    .wrapper {
      height: 100%;
    }

    &.s {
      height: auto;
      padding-top: 0 !important;
    }

    .map-column {
      width: 100%;
      margin: 0 0 2rem 0 !important;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .map-container {
      height: 450px;
    }

    .map-detail-container {
      margin-left: 1rem;
      margin-right: 1rem;
      width: calc(100% - 2rem) !important;
      top: unset;
      bottom: 0;
    }
  }
}
