.map-marker {
  background: $color-white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  display: flex;

  &:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: calc(50% - 8px);
    bottom: calc(100% - 3px);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 16px solid $color-white;
    z-index: 1;
  }

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    border: 4px solid $color-white;
    z-index: 2;
    box-sizing: border-box;
  }

  .img-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 50%;


    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}