$body-bg: #fff;
$font-primary: 'Montserrat', sans-serif;

$color-primary: #1e3953;
$color-secondary: #FEC601;
$color-tertiary: #E50056;
$color-white: #fff;
$color-light: #F4F4F4;
$color-bright: #E6E6E6;
$color-light-gray: #A0A0A0;
$color-gray: #4D4D4D;
$color-black: #000;


$color-success-light: #A7F3D0;
$color-success: #10B981;
$color-success-dark: #064E3B;


$color-danger-light: #FECACA;
$color-danger: #EF4444;
$color-danger-dark: #7F1D1D;

$color-warning: $color-secondary;

$section-margin-top: -5rem;
$section-padding-top: 5rem;
$section-padding-bottom: 10rem;
